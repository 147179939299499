import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3100744155/src/autobackup-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "azure-native-vms"
    }}>{`Azure Native VMs`}</h1>
    <p>{`Azure Native VMs view shows the status of backup operations managed by Azure Native backup vaults mechanism. AutoBackup periodically scans the status of Azure backup operations and shows the results in UI.`}</p>
    <h2 {...{
      "id": "open-list-of-azure-native-vms"
    }}>{`Open list of Azure Native VMs`}</h2>
    <ol>
      <li parentName="ol">{`Choose "Resources" from the navigation bar.`}</li>
      <li parentName="ol">{`Select "Azure Native VMs" from the navigation tab.
`}<img alt="Azure Native VMs view" src={require("../../assets/usage/azure-native-vms.png")} /></li>
    </ol>
    <h2 {...{
      "id": "showing-last-error-on-external-resource"
    }}>{`Showing last error on external resource`}</h2>
    <ol>
      <li parentName="ol">{`Choose "Resources" from the navigation bar.`}</li>
      <li parentName="ol">{`Select "Azure Native VMs" from the navigation tab.
`}<img alt="Azure Native VMs view" src={require("../../assets/usage/azure-native-vms.png")} /></li>
      <li parentName="ol">{`Click on "three dots" on the right side of the resource with `}<inlineCode parentName="li">{`error`}</inlineCode>{` backup status, and select "Show error" option.
`}<img alt="Azure Native VMs view" src={require("../../assets/usage/azure-native-vms-2.png")} /></li>
      <li parentName="ol">{`In the window that will appear you can see Azure Native VMs error message.
`}<img alt="Azure Native VMs view" src={require("../../assets/usage/azure-native-vms-3.png")} /></li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      